<template lang="pug">
  BaseSelect(v-model="data" :list="list" @input="handleChange" clearable :loading="loading")
</template>

<script>
import BaseSelect from './BaseSelect.vue';
import { getBrandList } from '@/utils/baseData';

export default {
  components: {
    BaseSelect,
  },
  props: ['value'],
  data() {
    return {
      list: [],
      data: this.value,
      loading: false,
    };
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e);
    },
  },
  watch: {
    value() {
      this.data = this.value;
    },
  },
  created() {
    this.loading = true;
    getBrandList()
      .then((infos) => {
        this.list = infos.map((e) => ({
          label: e,
          value: e,
        }));
        this.loading = false;
      });
  },
};
</script>
