import request from '@/utils/request';

const CACHE = {};

export const cacheList = (config, nocache = false) => new Promise((resolve) => {
  const cacheKey = config.url;
  if (CACHE[cacheKey] && !nocache) {
    resolve(CACHE[cacheKey]);
    return;
  }

  const noData = () => {
    CACHE[cacheKey] = null;
    resolve([]);
  };

  request({
    method: 'get',
    url: config.url,
    data: config.data,
  })
    .then((res) => {
      if (res.code === 1000) {
        CACHE[cacheKey] = res.data;
        resolve(res.data);
      } else {
        noData();
      }
    })
    .catch(noData);
});

export const getColors = (nocache = false) => cacheList({
  url: 'manager/color/get',
}, nocache);

export const getBrandList = (nocache = false) => cacheList({
  url: 'manager/brand/get',
}, nocache);

export const getRoleInfos = (nocache = false) => cacheList({
  url: 'manager/role/getList',
  data: {
    pageIndex: 1,
    pageSize: 1000,
  },
}, nocache);
