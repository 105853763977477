<template lang="pug">
.main-page.row
  el-form.demo-form-inline(:inline="true" :model="searchForm")
    el-form-item(label="车主姓名")
      el-input(v-model="searchForm.name")
    el-form-item(label="手机号")
      el-input(v-model="searchForm.phone")
    el-form-item(label="车牌号")
      el-input(v-model="searchForm.carNo")
    el-form-item(label="用户编号")
      el-input(v-model="searchForm.userNo")
    el-form-item(label="部门")
      el-input(v-model="searchForm.department")
    el-form-item(label="车主类型")
      CarLicenseTypeSelect(v-model="searchForm.type" clearable)
    el-form-item(label='绑定状态')
      BindStatusSelect(v-model='searchForm.bind_status' clearable)
    el-form-item(label="车辆颜色")
      ColorSelect(v-model="searchForm.color_txt" clearable)
    el-form-item(label="车辆品牌")
      BrandSelect(v-model="searchForm.brand" clearable)
    el-form-item(label='过期时间')
      el-date-picker(
        v-model='searchForm.expire_endtime',
        type='year',
        value-format='yyyy'
        placeholder="精确到年"
        :picker-options="expireTimeOption"
      )

    el-form-item
      el-button(type="primary" @click="refresh" v-loading="loading") 查询
      el-button(type="primary" @click="handleCreate" v-if="pemission['1.1.1']") 新增
      el-button(type="success" @click="showImport" v-if="pemission['1.1.3']") 导入

      //- :disabled="!multipleSelection.length"
      el-button(
        v-if="pemission['1.1.5']"
        type="success" @click="handleExport"
      ) 导出列表数据
      el-button(type='danger', @click='selectDelete' :disabled="!multipleSelection.length") 批量删除

  el-table(
    :data="tableData"
    stripe
    fit
    width="800"
    v-loading="loading"
    @selection-change='handleSelectionChange'
  )
    //- el-table-column(type='selection', width='55')
    el-table-column(prop="userNo" label="学号/教工编号")
    el-table-column(prop="carNo" label="车牌号码")
    el-table-column(prop="department" label="所属单位/学院")
    el-table-column(prop="name" label="车主姓名")
    el-table-column(prop="phone" label="联系方式")
    el-table-column(prop="typeName" label="车主类型")
    el-table-column(prop="image_front" label="车辆正视图")
      template(slot-scope="scope")
        el-button(
          v-if="scope.row.image_front"
          @click="showPic(scope.row.image_front)" type="text" size="small"
        ) 查看
        span(v-else) 暂无
    el-table-column(prop="image_behind" label="车辆侧视图")
      template(slot-scope="scope")
        el-button(
          v-if="scope.row.image_behind"
          @click="showPic(scope.row.image_behind)" type="text" size="small"
        ) 查看
        span(v-else) 暂无
    el-table-column(prop="brand" label="品牌")
    el-table-column(prop="color_txt" label="颜色")
    el-table-column(prop="name" label="操作" fixed="right" width="120" align="center")
      template(slot-scope="scope")
        el-dropdown(@command="(command) => handleClick(scope.row, command)" trigger="click")
          el-button(type="primary") 点击查看
          el-dropdown-menu(slot="dropdown")
            el-dropdown-item(command="look" icon="el-icon-search") 查看
            el-dropdown-item(command="edit" icon="el-icon-edit" v-if="pemission['1.1.2']") 编辑
            el-dropdown-item(command="delete" icon="el-icon-delete" v-if="pemission['1.1.4']") 删除
            el-dropdown-item(
              command="unbind" icon="el-icon-unlock"
              v-if="pemission['1.1.6'] && scope.row.carNo"
            ) 解绑
        //- el-button(@click="handleClick(scope.row)" type="text" size="small") 查看
        //- el-button(
        //-   v-if="pemission['1.1.2']"
        //-   @click="handleClick(scope.row, 'edit')"
        //-   type="text" size="small"
        //- ) 编辑
        //- el-button(
        //-   v-if="pemission['1.1.4']"
        //-   @click="handleClick(scope.row, 'delete')"
        //-   type="text" size="small"
        //- ) 删除

  el-pagination(
    background
    layout="prev, pager, next, total"
    :page-size="pageSize"
    :total="total"
    @current-change="pageChange"
  )

  el-dialog(
    title="导入"
    :visible.sync="importVisible"
    width="30%"
    :show-close="false"
    v-loading="loading"
  )
    .row
      el-form
        el-form-item(label="选择文件")
          el-upload(
            ref="upload"
            action="#"
            :show-file-list="true"
            :auto-upload="false"
            :limit="1"
            accept=".xlsx"
            :on-change="onFileUpload"
            :on-remove="removeImportFile"
            v-loading.fullscreen.lock="fullscreenLoading"
          )
            el-button(slot="trigger" size="small" type="primary") 选取文件
            //- el-row {{ importForm.json.length }}
    span(slot="footer" class="dialog-footer")
      el-button(@click="importVisible = false") 取 消
      el-button(type="primary" @click="handleImport") 确 定

  el-dialog(
    :title="formTitle"
    :visible.sync="formVisible"
    width="50%"
    :show-close="false"
  )
    .row
      el-form.demo-form-inline(
        :model="dialogForm"
        label-width="120px"
        :rules="rules"
        ref="dialogForm"
      )
        el-row
          h4 车主信息
          el-divider
        el-row
          el-col(:span="12")
            el-form-item(label="车主姓名" prop="name")
              el-input(v-model="dialogForm.name" placeholder="请输入")
          el-col(:span="12")
            el-form-item(label="联系方式" prop="phone")
              el-input(v-model="dialogForm.phone" placeholder="请输入")
          el-col(:span="12")
            el-form-item(label="车主类型" prop="type")
              CarLicenseTypeSelect(
                v-model="dialogForm.type" :clearable="false"
                @input="handleTypeChange"
              )
          el-col(:span="12" v-if="dialogForm.type === 'x'")
            el-form-item(label="所处年级" prop="userTypeDetail")
              BaseSelect(v-model="dialogForm.userTypeDetail" :list="classList" key="userTypeDetail")
          el-col(:span="12" v-if="dialogForm.type === 'y'")
            el-form-item(label="所处年级" prop="userTypeDetail")
              BaseSelect(
                v-model="dialogForm.userTypeDetail" :list="classList"
                key="userTypeDetail2"
              )
          el-col(:span="12" v-if="dialogForm.type === 'w'")
            el-form-item(label="合同截止时间" prop="userTypeDetail")
              el-date-picker(
                v-model="dialogForm.userTypeDetail"
                value-format='yyyy-MM-dd'
              )
          el-col(:span="12" v-if="dialogForm.type === 'j'")
            el-form-item(label="教职工详情" prop="userTypeDetail")
              BaseSelect(
                v-model="dialogForm.userTypeDetail" :list="teacherList"
                key="userTypeDetail3"
              )
          el-col(:span="12")
            el-form-item(label="所属单位" prop="department")
              el-input(v-model="dialogForm.department" placeholder="单位/学院")
          el-col(:span="12")
            el-form-item(label="编号" prop="userNo")
              el-input(v-model="dialogForm.userNo" :placeholder="userNoPlaceHolder")

        el-row
          h4 车辆信息
          el-divider
          el-col(:span="12")
            el-form-item(label="车辆品牌" prop="brand")
              BrandSelect(v-model="dialogForm.brand")
          el-col(:span="12")
            el-form-item(label="车辆颜色" prop="color_txt")
              ColorSelect(v-model="dialogForm.color_txt")
          el-col(:span="12" v-if="canSubmit === 'edit' || dialogForm.isBand")
            el-form-item(label="车牌号" prop="carNo")
              el-input(v-model="dialogForm.carNo" placeholder="车牌号" :disabled="dialogForm.isBand")
            //- el-col(:span="12" v-if="canSubmit === 'edit' && dialogForm.isBand")
          el-col(:span="12" v-if="canSubmit === 'edit' || dialogForm.isBand")
            el-form-item(label="禁用")
              el-switch(
                v-model="dialogForm.is_disable"
                active-text="是"
                inactive-text="否"
                active-color="#ff4949"
              )
          el-col(:span="12")
            el-form-item(label="备注" prop="remark")
              el-input(
                v-model="dialogForm.remark"
                placeholder="禁用原因/其他备注"
              )

          //- el-col(:span="12" v-if="canSubmit === 'edit'")
          //-   el-form-item(label="车牌状态")
          //-     BindStatusSelect(v-model="dialogForm.class")
    span(slot="footer" class="dialog-footer")
      //- el-row {{dialogForm}}
      el-button(@click="formVisible = false") 取 消
      el-button(
        type="primary"
        @click="updateCarInfo"
        :disabled="canSubmit === 'look'"
      ) 确 定

  el-dialog(
    title="查看图片"
    :visible.sync="imageVisible"
    width="800px"
  )
    el-image(
      style='width: 100%'
      :src="dialogImg"
      fit="cover"
    )

</template>

<script>
import xlsx from 'xlsx';
import request from '@/utils/request';
import {
  classList, teacherList, getPemission,
} from '@/utils/const';
import CarLicenseTypeSelect from '@/components/CarLicenseTypeSelect.vue';
import BindStatusSelect from '@/components/BindStatusSelect.vue';
import BaseSelect from '@/components/BaseSelect.vue';
import BrandSelect from '@/components/BrandSelect.vue';
import ColorSelect from '@/components/ColorSelect.vue';
import { jsonToExcel } from '@/utils/xlsx';

export default {
  components: {
    CarLicenseTypeSelect,
    BindStatusSelect,
    BaseSelect,
    BrandSelect,
    ColorSelect,
  },
  data() {
    return {
      classList,
      teacherList,
      pemission: getPemission(),
      expireTimeOption: {
        disabledDate(date) {
          // 当天可选：date.getTime() < Date.now() - 24 * 60 * 60 * 1000
          return date.getTime() < Date.now();
        },
      },
      searchForm: {
        expire_endtime: '',
      },
      importForm: {
        json: [],
      },
      dialogForm: {},
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        userTypeDetail: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        department: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        userNo: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        brand: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        color_txt: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        carNo: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      formVisible: false,
      total: 0,
      pageSize: 20,
      pageIndex: 1,
      loading: false,
      importVisible: false,
      fullscreenLoading: false,
      canSubmit: '',
      formTitle: '',
      tableData: [],
      dialogImg: '',
      imageVisible: false,
      multipleSelection: [],
    };
  },
  computed: {
    userNoPlaceHolder() {
      return {
        x: '学号',
        y: '学号',
        j: '工号 / 姓名+出生年月日 ',
        w: '姓名+出生年月日',
      }[this.dialogForm.type];
    },
  },
  methods: {
    refresh() {
      this.pageIndex = 1;
      this.search();
    },

    search() {
      const params = {
        ...this.searchForm,
        page: this.pageIndex,
        pagesize: this.pageSize,
      };
      if (params.expire_endtime) {
        params.expire_endtime += '-09-01';
      }

      this.getData(params);
    },

    getData(params) {
      this.loading = true;
      request({
        method: 'get',
        url: '/manager/caruser/getList',
        params,
      })
        .then((res) => {
          if (res.code === 1000) {
            this.total = res.data.count;
            this.tableData = res.data.list.map((e) => ({
              ...e,
              carNo: e.carNo,
              isBand: !!e.carNo,
            }));
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },

    pageChange(e) {
      this.pageIndex = e;
      this.search();
    },

    handleClick(row, target = 'look') {
      // console.log(row);
      if (target === 'look' || target === 'edit') {
        this.dialogForm = {
          ...row,
          bind_status: target === 'edit' ? 2 : 1, // 2: 已绑定： 1：未绑定
        };
        // console.log(this.dialogForm);
        this.showForm(target);
      } else if (target === 'delete') {
        this.handleDelete([row.id]);
      } else if (target === 'unbind') {
        this.handleUnbind(row);
      }
    },

    handleCreate() {
      this.dialogForm = {
        name: '',
        phone: '',
        type: 'x',
        userTypeDetail: '',
        department: '',
        userNo: '',
        brand: '小牛',
        color_txt: '黑色',
        is_disable: false,
        remark: '',
      };
      this.showForm('create');
    },

    handleDelete(id) {
      this.$confirm('此操作将永久删除该车辆信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      }).then(() => {
        this.doDelete(id);
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },

    doDelete(ids) {
      request({
        method: 'post',
        url: '/manager/caruser/deleteCarUser',
        data: { ids },
      })
        .then((res) => {
          if (res.code === 1000) {
            this.$message.success('删除成功');
            this.refresh();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },

    handleUnbind(row) {
      this.$confirm(`此操作将解绑该车辆编号${row.carNo}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      }).then(async () => {
        this.formTitle = '解绑';
        this.dialogForm = {
          ...row,
          bind_status: 1,
        };
        this.doSubmit();
      }).catch(() => {
        this.$message.info('已取消');
      });
    },

    removeImportFile() {
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
      this.importForm.json = [];
    },

    onFileUpload(e) {
      const fileType = e.name.split('.')[1];

      if (!['xlsx', 'xls'].includes(fileType)) {
        this.$message('只能上传 xls、xlsx格式');
        return;
      }

      if ((e.size / 1024) > (10 * 1024)) {
        this.$message('上传文件大小不能超过10MB!');
        return;
      }

      this.fullscreenLoading = true;
      this.makeFile(e.raw)
        .then((file) => {
          const worker = xlsx.read(file, { type: 'binary' });
          const json = xlsx.utils.sheet_to_json(worker.Sheets[worker.SheetNames[0]]);

          const format = json.map((item, i) => {
            const newObj = {};
            // eslint-disable-next-line
            for (let key in item) {
              const right = key.split('|')[1];
              if (right) {
                newObj[right] = item[key];
              }
            }
            if (!newObj.brand || !newObj.color_txt
              || !newObj.department || !newObj.name || !newObj.phone
              || !newObj.type || !newObj.userNo || !newObj.userTypeDetail
            ) {
              console.log(`第${i + 1}条数据不完整`, newObj);
            }
            return newObj;
          });
          console.log(format);
          this.importForm.json = format;
        })
        .catch(console.error)
        .then(() => {
          this.fullscreenLoading = false;
        });
    },

    handleImport() {
      if (!this.importForm.json.length) {
        this.$message.error('请选择要导入的文件');
      }

      this.loading = true;
      request({
        method: 'post',
        url: '/manager/caruser/batImportData',
        data: {
          data: this.importForm.json,
        },
      })
        .then((res) => {
          if (res.code === 1000) {
            this.$message.success('导入成功');
            this.importVisible = false;
            this.refresh();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },

    makeFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (ev) => {
          resolve(ev.target.result);
        };
        reader.onerror = () => {
          reject();
        };
        reader.onabort = () => {
          reject();
        };
      });
    },

    showForm(target = 'create') {
      this.canSubmit = target;
      this.formTitle = {
        create: '新建',
        edit: '编辑',
        look: '查看',
      }[target];
      this.formVisible = true;
    },

    handleTypeChange() {
      this.dialogForm.userTypeDetail = '';
    },

    showPic(path) {
      this.imageVisible = true;
      this.dialogImg = `https://znmzcp.fengyu.info${path}`;
    },

    /**
     * 新增 更新 车辆信息
     */
    updateCarInfo() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.doSubmit();
        }
      });
    },

    doSubmit() {
      // console.log(this.dialogForm);
      let url = '/manager/caruser/addCarUser';

      // name: this.dialogForm.name,
      // phone: this.dialogForm.phone,
      // type: this.dialogForm.type,
      // userTypeDetail: this.dialogForm.userTypeDetail,
      // department: this.dialogForm.department,
      // userNo: this.dialogForm.userNo,
      // brand: this.dialogForm.brand,
      // color_txt: this.dialogForm.color_txt,
      // bind_status: this.dialogForm.bind_status,
      // remark: this.dialogForm.remark,
      const data = {
        ...this.dialogForm,
        is_disable: this.dialogForm.is_disable ? 2 : 1, // 1正常，2禁用
      };
      if (['编辑', '解绑'].includes(this.formTitle)) {
        url = '/manager/caruser/editCarUser';
      }

      request({
        method: 'post',
        url,
        data,
      })
        .then((res) => {
          if (res.code === 1000) {
            this.formVisible = false;
            this.$message.success('保存成功');
            this.refresh();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },

    showImport() {
      this.removeImportFile();
      this.importVisible = true;
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    async getExportData() {
      this.multipleSelection = [];
      let page = 0;
      let total = 0;
      const getList = async () => {
        page += 1;
        const params = {
          ...this.searchForm,
          page,
          pagesize: 1000,
        };
        if (params.expire_endtime) {
          params.expire_endtime += '-09-01';
        }
        const res = await request({
          method: 'get',
          url: '/manager/caruser/getList',
          params,
        });
        total = res.data.count;
        this.multipleSelection = this.multipleSelection.concat(res.data.list);
        console.log(`total = ${total}, multipleSelection = ${this.multipleSelection.length}`, this.multipleSelection);
        if (total > this.multipleSelection.length) {
          await getList();
        }
      };

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      try {
        await getList();
      } catch (e) {
        this.$message.warning(e.message);
      } finally {
        loading.close();
      }
    },

    async handleExport() {
      await this.getExportData();
      if (!this.multipleSelection.length) {
        this.$message.warning('需要导出的数据为空');
        return;
      }
      // console.log(this.multipleSelection);
      const json = this.multipleSelection.map((e) => ({
        '学号/教工编号': e.userNo,
        车牌号码: e.carNo,
        是否绑定: e.isBand ? '是' : '否',
        '所属单位/学院': e.department,
        车主姓名: e.name,
        联系方式: e.phone,
        车主类型: e.typeName,
        类型详情: e.userTypeDetail,
        车辆正视图: e.image_front,
        车辆侧视图: e.image_behind,
        颜色: e.color_txt,
        品牌: e.brand,
        备注: e.remark,
      }));
      jsonToExcel({
        json,
        sheetName: '车辆列表',
        fileName: '车辆列表.xlsx',
        retType: 'file',
      });
    },

    selectDelete() {
      const ids = this.multipleSelection.map((e) => e.id);
      if (!ids.length) {
        this.$message.warning('请选择要删除的数据');
        return;
      }
      this.handleDelete(ids);
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style lang="less" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
